// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
// import designIcon from "./images/design.svg"
// import codeIcon from "./images/code.svg"
import linkedinIcon from "./images/linkedin.svg"
import angularIcon from "./images/angular-brands.svg"
import bootstrapIcon from "./images/bootstrap.svg"
import vueIcon from "./images/vuejs.svg"
import androidIcon from "./images/android-brands.svg"
import phpIcon from "./images/php.svg"
import sqlIcon from "./images/sql.svg"
import bombIcon from "./images/bomb.svg"
// import databaseMTA from "./images/MTA-Database_Fundamentals-600x600.png"
// import htmlMTA from "./images/MTA-HTML5_Application_Development_Fundamentals-600x600.png"

// Social Icon
import githubIcon from "./images/github.svg"
//import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"
import soundcloudIcon from "./images/soundcloud.svg"

export default {
  //(Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "cord",
  headerTagline: [
    //Line 1 For Header
    "Building digital",
    //Line 2 For Header
    "products, brands,",
    //Line 3 For Header
    "and experience",
  ],
  //   Header Paragraph
  headerParagraph: "Check out some of my recent works below",

  //Contact Email
  contactEmail: "cordpalmer23@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Indusa",
      para:
        "Custom built website for Indusa, a global leader in fluid management solutions and distribution.",
      imageSrc:
        "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/Portfolio/images/indusasnapshot.jpg",
      url: "https://www.indusapr.com/",
    },
    {
      title: "Hive Finance", //Project Title
      para: "Landing page built using Bootstrap", // Add Your Service Type Here
      //Project Image
      imageSrc:
        "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/portfolio_project04/images/hiveFinance.jpg",
      //Project URL
      url: "https://palmercord.github.io/hiveLanding/index.html",
    },
    {
      title: "Dreamscape Retreats",
      para:
        "Bootstrap designed web application with custom booking software written in Javascript",
      imageSrc:
        "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/Portfolio/images/dreamscape-retreats-screenshot.jpg",
      url: "https://palmercord.github.io/Dreamscape-Retreats-Git/index.html",
    },
    {
      title: "Vue Router",
      para: "Pokedex using Vue Router",
      imageSrc:
       "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/Portfolio/images/vue-router.jpg",
      url: "https://crwqf.csb.app/",
    },
    {
      title: "Vue Componenets",
      para: "Small showcase of the use of different Vue components.",
      imageSrc:
       "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/Portfolio/images/vue-components.jpg",
      url: "https://udrff.csb.app/",
    },
    {
      title: "Javascript To-do List", //Project Title
      para: "Simple to-do list made with javascript.", // Add Your Service Type Here
      //Project Image
      imageSrc:
        "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/portfolio_project04/images/toDoList.jpg",
      //Project URL
      url: "https://palmerCord.github.io/toDoList/index.html",
    },
    
  ],

  // End Work Section -----------------------

  // About Section --------------
  aboutParaOne:
    "I am a freelance front-end web and app developer, I have experience with HTML, CSS, Javascript, Php and SQL. I have experience with multiple css frameworks including Bootstrap and Sass.",
  aboutParaTwo:
    "To go along with my experience in Javascript, I built this website using React. I have built web-based programs using Angular, Vue, React, and React-Native to build Android and IOS applications. I love building clean, smooth UI/UX and using animations where it counts. ",
    aboutParaThree:
    "I have a passion for snowboarding, web/software design and photography. I have posted links to my Soundcloud and Instagram pages at the bottom.", 
    aboutParaFour:
    "I was born and raised in Boise, Idaho and have been living in Puerto Rico since early 2020. I am a full time remote software development student with no intentions of slowing down my education. I am studying to become a full-stack software engineer. I am studious, team oriented and am looking for a quality position to learn and grow as and individual, designer, and a developer.",
  aboutImage:
    "https://raw.githubusercontent.com/PalmerCord/PalmerCord.github.io/main/portfolio_project04/images/keyboardbackground.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: htmlIcon,
      // para:
      //   "",
    },
    {
      img: cssIcon,
      // para:
      // "",
    },
    {
      img: bootstrapIcon,
    },
    {
      img: jsIcon,
      // para:
      // "",
    },
    {
      img: reactIcon,
      // para:
      // "",
    },
    {
      img: angularIcon,
    },
    {
      img: vueIcon,
    },
    {
      img: androidIcon,
    },
    {
      img: phpIcon,
    },
    {
      img: sqlIcon,
    },
    // {
    //   img: codeIcon,
    // },
    // {
    //   img: bombIcon,
    // },
    // {
    //   img: htmlMTA,
    //   url: "https://www.credly.com/badges/a2012b81-f3d5-44ba-a69d-d76ac5ec4ac1/public_url",
    // },
    // {
    //   img: databaseMTA,
    //   url: "https://www.credly.com/badges/bc5502bb-9cd3-4352-9da9-49f5145cd453/public_url",
    // },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  // promotionHeading: "",
  // promotionPara:
  //   "",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together.",
  contactPara: "ATTN: Check out my Github and connect on other social pages!",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: githubIcon,
      url: "https://github.com/PalmerCord",
    },
    // {
    //   img: githubIcon,
    //   url: "",
    // },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/cordpalmer/",
    },
    {
      img: soundcloudIcon,
      url: "https://soundcloud.com/cord-palmer",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/cordian23",
    },
    {
      img: bombIcon,
      url: "https://www.facebook.com/cord.palmer.5/",
    },
    // {
    //   img: htmlMTA,
    //   url: "https://www.credly.com/badges/a2012b81-f3d5-44ba-a69d-d76ac5ec4ac1/public_url",
    // },
    // {
    //   img: databaseMTA,
    //   url: "https://www.credly.com/badges/bc5502bb-9cd3-4352-9da9-49f5145cd453/public_url",
    // },
    
  ],

  // End Contact Section ---------------
}

